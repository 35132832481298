import { Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import SocialShare from '../../Components/SocialShare';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const DELETE_GALLERY_URL = '/galleries/delete/';
const DELETE_IMAGE_URL = '/pictures/delete/';
const VIEW_GALLERY_URL = '/galleries/view/';
const EDIT_GALLERY_URL = '/galleries/edit/';
const DELETE_FILE_URL = '/fileDelete/';
const UPLOAD_FILE_URL = '/upsingle';

function ViewGallery({PF}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [gallery, setGallery] = useState({});  
  const [editGallery, setEditGallery] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate();
  let {id} = useParams();
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_GALLERY_URL + id).then((response)=>{
      if(isMounted){
        setGallery(response.data.gallery);
        setName(response.data.gallery.name);
        setDescription(response.data.gallery.description);
        setImages(response.data.images);  
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]); 
  
  const deleteGallery = async(id) => {
    try{
      axiosPrivate.post(DELETE_FILE_URL + gallery.picture).then(()=>{
        console.log('Gallery image deleted');
      }).catch(function(error){
        console.log(error.config);
      });

      axiosPrivate.delete(DELETE_GALLERY_URL + id).then(()=>{
        alert('Gallery Deleted');
        navigate('/gallery');
      });               
    }catch(error){
      console.log(error);       
    }
  }

  const addGalleryPics = async(id) => {
    navigate(`/gallery/addpics/${id}`);
  }

  const deleteImage = async(id, picture) => {
    try {
      //Delete file from Server before proceeding
      axiosPrivate.post(DELETE_FILE_URL + picture).then(()=>{
        console.log('File Deleted from Server');
      }).catch(function(error){
        console.log(error.config);
      });

      //Delete image record from database
      axiosPrivate.delete(DELETE_IMAGE_URL + id).then(()=>{
        alert('Image Deleted from Gallery');
        navigate(0);        
      }).catch(function(error){
        console.log(error.config);
      });
    } catch (error) {
      console.log(error);     
    }
  }

  const updateGallery = async(e) => {
      e.preventDefault();
      const updatedGallery = {name, description, userId: authDecode.user.id, id: gallery.id};
      const galleryId = gallery.id;
  
      if(file){
        if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
          alert("Wrong file type. Upload a jpg, jpeg or png");
          navigate(0);
        }else if (file.size > 700000) {
          alert("Image file is too large - Upload file less than 700kb");
          navigate(0);
        }else {
          const formData = new FormData();
          const filename = Date.now() + '--' + file.name;
              
          formData.append("name", filename);
          formData.append("file", file);
          updatedGallery.picture = filename;
        
          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);
          }catch(err){
            console.log(err);
          }
        }
      }
        
      try {
        axiosPrivate.put(EDIT_GALLERY_URL + galleryId, updatedGallery).then(()=>{
          alert('Gallery Edited');
          setEditGallery(false);
          navigate(`/gallery/view/${galleryId}`);
        });   
      }catch (error) {
          console.log(error);  
      }
  }

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( images.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === images.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  const currentPage = window.location.href;

  return (
    <div className="col-lg-8 mb-3">
      <div className='container-fluid mb-3'>
        <div className="col-lg-12 px-0">
          <div className="position-relative overflow-hidden">
            <div className='row'>
              <div className='col-lg-4 w-100'>
              { file ? (
                  <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" style={{objectFit: 'cover'}} />
              ): (
                gallery?.picture && <img src={PF + gallery.picture} alt="" className="img-fluid w-100" style={{objectFit: 'cover'}}  />
              )}
              </div>
              <div className='col-lg-8'>
                { editGallery ? ( 
                  <form>
                    <div className="form-group"> 
                        <label htmlFor="fileInput">
                        Change Image: <i className="writeIcon fas fa-recycle" />
                        </label> 
                        <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                        Title: 
                        </div>
                        <div className="form-group col-md-9">
                        <input className="form-control p-2" required="required" type='text' value={name} onChange={(e)=>setName(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-3">
                        Description: 
                        </div>
                        <div className="col-md-9">
                        <div className="form-group">                                    
                            <textarea className="form-control p-2" rows="2" value={description} onChange={(e)=>setDescription(e.target.value)} />
                        </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={updateGallery}>Update</button>
                        &nbsp;&nbsp;&nbsp;  
                        <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setEditGallery(false)}>Cancel</button>
                    </div>
                  </form> 
                ):(<>
                    <h4 className="mb-3 text-secondary text-capitalize font-weight-bold">{gallery.name}</h4>
                    <SocialShare currentPage={currentPage} title={gallery?.name}/>
                    <p>{gallery.description}</p>
                    {authDecode && (authDecode?.user?.id === gallery.UserId || authDecode?.user?.role >=4) ? (
                      <div className="row">
                          <div className="p-2">
                            <i className="singleIcon far fa-file-image" aria-hidden="true" onClick={() => addGalleryPics(gallery.id)}/>
                          </div>
                          <div className="p-2">
                            <i className="singleIcon far fa-edit" aria-hidden="true" onClick={() => setEditGallery(true)}/>
                          </div>
                          <div className="p-2">
                            <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteGallery(gallery.id)} />
                          </div>
                      </div>
                    ) : (<></>)}
                </>)}
              </div>
            </div>
          </div>
        </div>        
      </div>
      <div className="col-lg-12">
        <div className="row">
          {openModal &&
            <div className="col-lg-12">
              <div className='sliderWrap'>
                <span className='btnClose' onClick={handleCloseModal}>X</span>
                <span className='btnPrev' onClick={prevSlide}>&#10094;</span>
                <span className='btnNext' onClick={nextSlide}>&#10095;</span>
                <div className='fullScreenImage'>
                  <img src={PF + images[slideNumber].picture} alt='' /><br />
                </div>
              </div>
            </div>              
          }
          { images && images.map((slide, index) => {
            return(
              <div className='col-3 position-relative mb-3' key={slide.id}>
                <img className='img-fluid w-100' src={PF + slide.picture} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} onClick={ () => handleOpenModal(index) } />
                {authDecode && (authDecode?.user?.id === gallery.UserId || authDecode?.user?.role >=4) && (
                  <Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteImage(slide.id, slide.picture)} /></Link>
                )}
              </div>
            )           
          })}
        </div>
      </div>
    </div>
  )
}

export default ViewGallery