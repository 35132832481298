import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useParams, useNavigate } from 'react-router-dom';
import SocialShare from '../../Components/SocialShare';
import React, { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DELETE_ARTICLE_URL = '/articles/delete/';
const EDIT_ARTICLE_URL = '/articles/edit/';
const VIEW_ARTICLE_URL = '/articles/view/';
const DELETE_FILE_URL = '/fileDelete/';
const UPLOAD_FILE_URL = '/upsingle';

function ArticleContent({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [articlePost, setArticlePost] = useState({});  
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const [postAuthor, setPostAuthor] = useState({});  
    const [postPrev, setPostPrev] = useState({});  
    const [postNext, setPostNext] = useState({});  
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ARTICLE_URL + id).then((response)=>{
            if(isMounted){
                setArticlePost(response.data.oneArticle);  
                setTitle(response.data.oneArticle.title);
                setDetail(response.data.oneArticle.detail);
                setPostAuthor(response.data.articleAuthor);           
                setPostPrev(response.data.prevArticle);
                setPostNext(response.data.nextArticle);    
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deletePost = async(id) => {
        try {
            axiosPrivate.post(DELETE_FILE_URL + articlePost.picture).then(()=>{
                console.log('Old image deleted');
            }).catch(function(error){
                console.log(error.config);
            });

            axiosPrivate.delete(DELETE_ARTICLE_URL + id).then(()=>{
                alert('Article Deleted');
                navigate('/articles');
            });               
        } catch (error) {    }
    };

    const onDetail = (value) => {
        setDetail(value);
    } 

    const updatePost = async(e) => {
        e.preventDefault();
        const updatedStory = {title, detail, userId: authDecode.user.id, id: articlePost.id};

        if(file){
            axiosPrivate.post(DELETE_FILE_URL + articlePost.picture).then(()=>{
                console.log('Old image deleted');
            }).catch(function(error){
                console.log(error.config);
            }); 

            const formData = new FormData();
            const filename = Date.now() + file.name;
            
            formData.append("name", filename);
            formData.append("file", file);
            updatedStory.picture = filename;
      
            try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_ARTICLE_URL + articlePost.id, updatedStory).then(()=>{
                alert('Article Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }
    const currentPage = window.location.href;

    return (
        <div className="col-lg-8 mb-3"> 
            { updateMode ? (
                <div className="section-title mb-3">
                    <h4 className="m-0 text-uppercase font-weight-bold">Edit Article</h4>
                </div>
            ):(<>
                <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">{articlePost?.title}</h2>
                {authDecode && (authDecode?.user?.id === articlePost.userId || authDecode?.user?.role >= 4) ? (
                    <div className="singleEdit">
                        <i className="singleIcon far fa-edit mr-2" aria-hidden="true" onClick={() => setUpdateMode(true)}/>
                        <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deletePost(articlePost.id)} />
                    </div>
                    
                ):(<></>)}
                
                <div className="mb-3">
                    <span className="singleAuthor">By: <b>{postAuthor?.name}</b></span>
                    <span className="singleDate"> -- {new Date(articlePost?.createdAt).toDateString()}</span>
                </div>                    
            </>)}
            <SocialShare currentPage={currentPage} title={articlePost?.title}/>
            <div className="bg-white border border-top-0">
                { file ? (
                    <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                ): (
                    articlePost?.picture && <img src={PF + articlePost.picture} alt="" className="img-fluid w-100" style={{height: '500px', objectFit: 'cover'}} />
                )}
                { updateMode ? 
                    <form className="p-2">
                        <div className="form-group">
                            <label htmlFor="fileInput">
                                Change Image: <i className="writeIcon fas fa-recycle" />
                            </label>
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                        </div>
                        <div className="form-group">
                            Title: 
                            <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                        </div>
                        <div className="form-group">
                            Article Content:
                            <ReactQuill
                                theme="snow" rows="10"
                                value={detail}
                                onChange={onDetail}
                                placeholder={"Tell your story ..."}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={updatePost}>Update</button>
                        &nbsp;&nbsp;&nbsp;  
                        <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                    </form> 
                    : (<div className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: articlePost.detail}} style={{whiteSpace: "pre-line"}} />)
                }
                <div className='row p-2'>
                    {postNext?.picture &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/articles/view/${postNext.id}`)}>
                            <img className="img-fluid h-100" src={PF + postNext.picture} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">                                                
                                <div className="mb-2">
                                    <Link className="text-white" to="/"><small>{new Date(postNext.createdAt).toDateString()}</small></Link>
                                </div>
                                Next: <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/articles/view/${postNext.id}`}>{postNext.title}</Link>
                            </div>
                        </div>
                    }
                    {postPrev?.picture &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/articles/view/${postPrev.id}`)}>
                            <img className="img-fluid h-100" src={PF + postPrev.picture} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                <div className="mb-2">
                                    <Link className="text-white" to="/"><small>{new Date(postPrev.createdAt).toDateString()}</small></Link>
                                </div>
                                Previous: <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/articles/view/${postPrev.id}`}>{postPrev.title}</Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ArticleContent