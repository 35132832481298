import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import SocialShare from '../../Components/SocialShare';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DELETE_PROFILE_URL = '/profiles/delete/';
const VIEW_PROFILE_URL = '/profiles/view/';
const EDIT_PROFILE_URL = '/profiles/edit/';
const DELETE_FILE_URL = '/fileDelete/';
const UPLOAD_FILE_URL = '/upsingle';

function ProfileContent({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [profile, setProfile] = useState({});  
    const [profileTitle, setProfileTitle] = useState('');
    const [profileText, setProfileText] = useState('');
    const [profilePrev, setProfilePrev] = useState({});
    const [profileNext, setProfileNext] = useState({});  
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            if(isMounted){
                setProfile(response.data.profile);
                setProfileTitle(response.data.profile.profileTitle);
                setProfileText(response.data.profile.profileText);
                setProfilePrev(response.data.prevProfile)
                setProfileNext(response.data.nextProfile)    
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deleteProfile = async(id) => {
        try {
            axiosPrivate.post(DELETE_FILE_URL + profile.pictures).then(()=>{
                console.log('Picture Deleted');
            }).catch(function(error){ console.log(error.config); });

            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Profile Deleted');
                navigate('/profiles');
            });               
        } catch (error) {            
        }
    };

    const onProfileText = (value) => {
        setProfileText(value);
    } 

    const updateProfile = async(e) => {
        e.preventDefault();
        const updatedProfile = {profileTitle, profileText, userId: authDecode.user.id, id: profile.id};
        const profileId = profile.id;

        if(file){
            axiosPrivate.post(DELETE_FILE_URL + profile.pictures).then(()=>{ 
                console.log('Old Picture deleted from server');
            }).catch(function(error){ console.log(error.config); });

            const formData = new FormData();
            const filename = Date.now() + file.name;            
            formData.append("name", filename);
            formData.append("file", file);
            updatedProfile.pictures = filename;
      
            try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Profile Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
              console.log(error);  
        }
    }

    const currentPage = window.location.href;

    return (
        <div className="col-lg-8">
            {updateMode ? (
                <div className="section-title mb-3">
                    <h4 className="m-0 text-uppercase font-weight-bold">Edit Profile</h4>
                </div>
            ):(<>
                <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">{profile?.profileTitle}</h2>
                {authDecode && (authDecode?.user?.id === profile.UserId || authDecode?.user?.role >=4) ? (
                    <div className="singleEdit">
                        <i className="singleIcon far fa-edit mr-2" aria-hidden="true" onClick={() => setUpdateMode(true)}/>
                        <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteProfile(profile.id)} />
                    </div>
                ) : (<></>)}                
                <div className="mb-3">
                    <span className="singleDate">{new Date(profile?.createdAt).toDateString()}</span>
                </div>                    
            </>)}
            <SocialShare currentPage={currentPage} title={profile?.profileTitle}/>
            <div className="bg-white border border-top-0">
                { file ? (
                    <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                ): (
                    profile?.pictures && <img src={PF + profile?.pictures} alt="" className="img-fluid w-100" style={{height: '500px', objectFit: 'cover'}} />
                )}
                { updateMode ? (
                    <form className="p-2">
                        <div className="form-group">
                            <label htmlFor="fileInput">
                                Change Profile Image: <i className="writeIcon fas fa-recycle"></i>
                            </label>
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                        </div>
                        <div className="form-group">
                            Title: 
                            <input type="text" value={profileTitle} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setProfileTitle(e.target.value)} />
                        </div>
                        <div className="form-group">
                            Article Details:
                            <ReactQuill theme="snow" rows="10" value={profileText} onChange={onProfileText} />
                        </div>
                        <button className="btn btn-primary font-weight-semi-bold px-4" type="submit" onClick={updateProfile}>Update</button>
                        &nbsp;&nbsp;&nbsp;  
                        <button className="btn btn-primary font-weight-semi-bold px-4" onClick={()=>setUpdateMode(false)}>Cancel</button>
                    </form>
                ):(
                    <div className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: profile?.profileText}} style={{whiteSpace: "pre-line"}} />
                )}
                <div className='row p-2'>
                    { profileNext?.pictures &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/profiles/view/${profileNext.id}`)}>
                            <img className="img-fluid h-100" src={PF + profileNext.pictures} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                Next:
                                <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/profiles/view/${profileNext.id}`}>{profileNext.profileTitle}</Link>
                            </div>
                        </div>
                    }
                    { profilePrev?.pictures &&
                        <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "300px"}} onClick={() => navigate(`/profiles/view/${profilePrev.id}`)}>
                            <img className="img-fluid h-100" src={PF + profilePrev.pictures} style={{objectFit: "cover"}} alt="" />
                            <div className="overlay">
                                Previous:
                                <Link className="h6 m-0 text-white text-uppercase font-weight-semi-bold" to={`/profiles/view/${profilePrev.id}`}>{profilePrev.profileTitle}</Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProfileContent
